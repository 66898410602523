h1,
.h1 {
  font-size: 2rem;
}

.nav-item .nav-link svg:first-child {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.7rem;
}

.nav-item + div .nav-item .nav-link svg:first-child {
  width: 1rem;
  height: 0.6rem;
  margin-left: 6px;
}

.cr-sidebar__nav-item span {
  font-size: 14px;
}

form.form-inline .form-group {
  margin-right: 10px;
}

form.form-inline .form-control {
  width: 100%;
}

table {
  overflow-x: auto;
  margin-bottom: 5px !important;
}

table thead tr th,
table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  min-width: 200px;
  padding: 8px;
  /* white-space: nowrap; */
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.rdtPicker {
  display: block;
  position: static;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px 0;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  color: #2a87d0;
}

.quickaccess ul {
  padding-left: 22px;
}

.quickaccess ul li {
  padding-bottom: 3px;
}

.rowoverview label.data {
  color: #6a82fb;
}

#pnltable tr td:not(:nth-child(1)),
#pnltable tr td:nth-child(2) input {
  text-align: center;
}

#pnltable tr:first-child td:last-child {
  text-align: left;
}

#pnltable tr:not(:first-child) td:nth-child(3) {
  background-color: #00000013;
}

#balancesheettable tr th,
#balancesheettable tr td,
#balancesheettable tr td input {
  text-align: center;
}

.view {
  margin: auto;
}

.wrapper {
  position: relative;
  overflow: auto;
  border: 1px solid black;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white !important;
}

.first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}

.second-col {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  left: 100px;
}

.fix-column-view {
  margin: auto;
}

.fix-column-wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

/* .fix-column-wrapper .rdt_TableHeadRow [data-column-id="1"],.fix-column-wrapper .rdt_TableRow [data-column-id="1"]{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
    position: -webkit-sticky;
    position: sticky;
    background-color: white !important;
    z-index: 1000;
}

.fix-column-wrapper .rdt_TableHeadRow [data-column-id="2"],.fix-column-wrapper .rdt_TableRow [data-column-id="2"]{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 100px;
    position: -webkit-sticky;
    position: sticky;
    background-color: white !important;
    z-index: 1000;
} */

.freezestyle {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
  /* left: 0px !important; */
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: white !important;
  /* z-index: 1000 !important; */
}

.freezecol {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
  /* left: 0px !important; */
  position: -webkit-sticky !important;
  position: sticky !important;
  /* background-color: #343a40 !important; */
  /* z-index: 2; */
}

.table-dark .freezecol {
  background-color: #343a40 !important;
}

.accordion-header button,
.accordion-header button:focus,
.accordion-header button:focus-visible {
  background: transparent !important;
  border: none !important;
  font-size: 14px !important;
  color: white !important;
}

.bg-muted {
  background-color: #888888;
}

#tableccsq tbody tr td {
  font-size: 12px;
  vertical-align: top;
}

.display-print-only {
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  .display-print-only {
    display: block;
  }
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999998;
  /* background: url("../images/logo.png") no-repeat; */
  background-position: center 45%;
  background-color: rgba(0, 0, 0, 0.85);
  background-size: 30vw;
}

.loader {
  width: 22px;
  height: 22px;
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  margin-top: 20px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
  -moz-animation: loader 0.8s infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 2px solid #ffffff;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    border: 2px solid #ffffff;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    border: 2px solid #ffffff;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 2px solid #1c047d;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    border: 2px solid #1c047d;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    border: 2px solid #1c047d;
    border-left-color: transparent;
  }
}

.loader--hide {
  display: none;
}

.input-group-text {
  background-color: rgb(255, 255, 255);
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-header button,
.accordion-header button:focus,
.accordion-header button:focus-visible {
  background-color: #2a87d0 !important;
  border-radius: 5px;
  padding: 10px 15px;
}

.accordion-item .accordion-collapse {
  border: 1px solid rgb(221, 221, 221);
  padding: 10px;
  border-radius: 5px;
}

.react-confirm-alert-body h1 {
  font-size: 25px;
}

#tableportfoliotrades tbody tr:nth-child(n + 3) {
  background-color: initial;
  color: initial;
}

#swal2-title {
  font-size: 20px;
}

.table-white tbody tr td {
  background-color: #ffffff;
}

.custom-vertical-table {
  width: 100%;
  overflow-x: scroll;
  display: block;
  border: 1px solid #dddddd;
  max-height: 50vh;
}
